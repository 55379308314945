import React, { useContext, useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { graphql, Link, navigate } from 'gatsby';
import { Transition } from 'react-transition-group';
import Layout from "../components/layout";
import ThemeContext from '../context/ThemeContext';
import { Marker, InfoWindow } from "react-google-maps";
import Map from "../components/map";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import LocationCard from "../components/locationCard";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import prepareSchemaHours from "../helpers/prepareSchemaHours";
import styles from "./locations.module.scss";

export default (props) => {
	const {
		data: {
			page,
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}

			},
			locations: {
				nodes: locations
			},
			arrowRedLocal,
			arrowDownGray,
			markerIcon
		}
	} = props;

	const locationScrollList = useRef();
	const lastElementInList = useRef();
	const selectRef = useRef();
	const zipRef = useRef();
	const { location: useLocation, getLocation, setLocation } = useContext(ThemeContext);
	const [ initialSortHasHappened, setInitialSortHasHappened ] = useState(false);
	const [ filterError, setFilterError ] = useState("");
	const [currentMarker, setCurrentMarker] = useState(null);
	const [sortedLocations, setSortedLocations] = useState( locations );
	const [expandSearch, setExpandSearch] = useState(false);
	const [showMoreIndicator, setShowMoreIndicator] = useState(false);
	const [schemaData, setSchemaData] = useState(null);

	const expandSearchRadius = () => {
		setExpandSearch(true);
	}

	const sortByDistance = async (showAll) => {
		let locationList = [];

		await locations.forEach((location, i) => {   
			const distance = getDistanceFromLatLonInMi(location.birdeyeLocation.location.lat, location.birdeyeLocation.location.lng, useLocation.lat, useLocation.lng);
			if( !showAll ){
				if( distance <= 30){
					location.distance = distance;
					locationList.push(location);
				} else {
					locations[i].distance = distance;
				}
			} else {
				location.distance = distance;
				locationList.push(location);
			}
		});

		if( locationList.length < 1 ){
			locations.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
			
			locationList = locations.splice(0,3);
		} else {
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
		}

		// apply sorted list to state
		setSortedLocations(locationList);


		function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = deg2rad(lat2-lat1);  // deg2rad below
			var dLon = deg2rad(lon2-lon1); 
			var a = 
				Math.sin(dLat/2) * Math.sin(dLat/2) +
				Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
				Math.sin(dLon/2) * Math.sin(dLon/2)
				; 
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
			var d = R * c; // Distance in km
			var miles = d * 0.621371;
			return miles;
		}

		function deg2rad(deg) {
			return deg * (Math.PI/180)
		}
	}

	const filterLocations = async () => {

		setFilterError("");

		const zipCall = async () => {
			const zipUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=" + zipRef.current.value + "&key=" + process.env.GATSBY_GOOGLE_GEOCODE_API_KEY;

			try {
				await fetch(zipUrl)
				.then(data => data.json())
				.then(data => {
					if( data && data.status === "OK"){
						if( data.results.length ){
							setLocation({
								lat: data.results[0].geometry.location.lat,
								lng: data.results[0].geometry.location.lng
							});
							setExpandSearch(false);
						} else {
							alert("Sorry, that zipcode did not return a location.")
						}
					} else {
						alert("Sorry, that zipcode did not return a location.")
					}
				});
			} catch (error) {
				console.log(error);
			}
		}

		if( selectRef && selectRef.current && selectRef.current.options.selectedIndex && selectRef.current.options[selectRef.current.options.selectedIndex].value && selectRef.current.options[selectRef.current.options.selectedIndex].value !== false ){
			navigate(selectRef.current.options[selectRef.current.options.selectedIndex].value);
		} else if( zipRef && zipRef.current && zipRef.current.value && zipRef.current.value.length === 5 ){
			zipCall();
		} else if( zipRef && zipRef.current && zipRef.current.value && zipRef.current.value.length > 0 && zipRef.current.value.length !== 5 ){
			setFilterError("zip")
		} else {
			setFilterError("both")
		}
	};

	const showInfo = (i) => {
		setCurrentMarker(i);
	};

	useEffect(() => {
		if (locations.length < 1) {
			return;
		}

		const bowmanLocation = locations.find(location => location.title.includes('Bowman'));
		const nonBowmanLocations = locations.filter(location => !location.title.includes('Bowman'));

		if (!bowmanLocation) {
			return;
		}

		const deparments = nonBowmanLocations.map(location => {
			const deptHours = prepareSchemaHours(location.birdeyeLocation.hoursOfOperations);
			return `{
				"@context": "https://schema.org",
				"@type": "Dentist",
				"name": "${location.birdeyeLocation.name}",
				"url": "${location.link}",
				"telephone": "${location.birdeyeLocation.phone}",
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "${location.birdeyeLocation.location.address1} ${location.birdeyeLocation.location.address2}",
					"addressLocality": "${location.birdeyeLocation.location.city}",
					"addressRegion": "${location.birdeyeLocation.location.state}",
					"postalCode": "${location.birdeyeLocation.location.zip}",
					"addressCountry": "US"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": ${location.birdeyeLocation.location.lat},
					"longitude": ${location.birdeyeLocation.location.lng}
				},
				"openingHours": [${deptHours}]
			}`;
		});

		const bowmanLocHours = prepareSchemaHours(bowmanLocation.birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${bowmanLocation.birdeyeLocation.name}",
			"url": "${bowmanLocation.link}",
			"telephone": "${bowmanLocation.birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${bowmanLocation.birdeyeLocation.location.address1} ${bowmanLocation.birdeyeLocation.location.address2}",
				"addressLocality": "${bowmanLocation.birdeyeLocation.location.city}",
				"addressRegion": "${bowmanLocation.birdeyeLocation.location.state}",
				"postalCode": "${bowmanLocation.birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${bowmanLocation.birdeyeLocation.location.lat},
				"longitude": ${bowmanLocation.birdeyeLocation.location.lng}
			},
			"openingHours": [${bowmanLocHours}],
			"department": [${deparments}]
		}`;

		setSchemaData(newSchema);
	}, []);

	const scrollToLast = () => {
		if( lastElementInList && lastElementInList.current ){
			lastElementInList.current.scrollIntoView({ 
				behavior: "smooth", 
				block: "nearest"
			});
		}
	};

	useEffect(() => {
		const locationListScrolled = () => {
			locationScrollList.current.removeEventListener('scroll', locationListScrolled);
			setShowMoreIndicator(false);
		}

		const locScrlCrrnt = locationScrollList.current;
		// on component mount
		sortByDistance(expandSearch);
		getLocation();
		setInitialSortHasHappened(true);

		return () => {
			if( locScrlCrrnt ){
				locScrlCrrnt.removeEventListener('scroll', locationListScrolled);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(initialSortHasHappened){
			sortByDistance(expandSearch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expandSearch, useLocation]);

	useEffect(() => {
		const locationListScrolled = () => {
			locationScrollList.current.removeEventListener('scroll', locationListScrolled);
			setShowMoreIndicator(false);
		}

		const moreLocationsIndicator = () => {
			if( locationScrollList && locationScrollList.current && locationScrollList.current.parentElement ){
				if( locationScrollList.current.scrollHeight > locationScrollList.current.parentElement.offsetHeight ){
					locationScrollList.current.addEventListener('scroll', locationListScrolled);
					setShowMoreIndicator(true);
				} else {
					locationScrollList.current.removeEventListener('scroll', locationListScrolled);
					setShowMoreIndicator(false);
				}
			}
		};
		
		if( locationScrollList.current ){
			moreLocationsIndicator();
		}


	}, [locationScrollList, sortedLocations]);

	const showMoreTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 1,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	return (
		<Layout>
			<Seo post={page} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<div className={`${styles.Locations} hero`}>
				<div className={styles.container}>
					<div className={styles.top}>
						<h1 className="homeh1">Leap Kids Dental Locations</h1>
						{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
						<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />
					</div>
					<div className={styles.middle}>
						
						{ page?.Locations.pageHeading && <h2 className={styles.pagetitle} dangerouslySetInnerHTML={{__html: page.Locations.pageHeading}}></h2> }
						{ page?.Locations?.pageBlurb && <div className={styles.pageblurb} dangerouslySetInnerHTML={{__html: page.Locations.pageBlurb}}></div> }
					</div>
					<div className={styles.bottom}>
						<div className={styles.left} ref={locationScrollList}>
							<div className={styles.inner}>
								{ sortedLocations && sortedLocations.map((location, i) => {   
									return (
										<div key={location.id} className={styles.cardcontainer} ref={i === (sortedLocations.length -1) ? lastElementInList : null}>
											<LocationCard 
												key={location.id} 
												location={location} 
												arrowRedLocal={arrowRedLocal} 
												isTeamPage={true} 
												isLocationsPage={true}
											/>
										</div>
									);
								})} 
								{ sortedLocations.length === 0 && (
									<div className={styles.cardcontainer}>
										<p className={styles.nolocations}>No locations found nearby. Try expanding your search.</p>
									</div>	
								)}
								{ locations.length !== sortedLocations.length && <button className={styles.expand} onClick={()=>{ expandSearchRadius(); }}>Show all locations</button> }
							</div>
							<Transition in={showMoreIndicator} timeout={250} mountOnEnter unmountOnExit>
								{(state) => (
									<button aria-label="Scroll down" className={styles.showmore} onClick={(event)=>{ scrollToLast(); }} style={{...showMoreTransitionStyles[state]}}>More locations <FixedImage image={arrowDownGray} passedClass={styles.icon} /></button>
								)}
							</Transition>
						</div>
						<div className={styles.right}>
							<div className={styles.filters}>
								<div className={styles.LocationSelector}>
									<span className={styles.heading}>Quick Select:</span>
									<div className={ (filterError === "location" || filterError === "both") ? styles.selectcontainererror : styles.selectcontainer}>
										<select className={styles.select} ref={selectRef} aria-label="Quick select a location:">
											<option value={false}>Choose a location</option>
											{ locations.map( (location, index) => {
												return <option key={location.id} value={location.link} aria-label={`Select ${location.title}`}  dangerouslySetInnerHTML={{__html: location.title}}></option>
											})}
										</select>	
									</div>
								</div>
								<div className={styles.ZipSelector}>
									<span className={styles.heading}>Search by ZIP:</span>
									<input type="text" aria-label="Search by ZIP:" ref={zipRef} className={ ( filterError === "zip" || filterError === "both") ? styles.zipinputerror : styles.zipinput} placeholder="Zip Code" />
									<button className={styles.go} aria-label="Go to location" onClick={(event)=>{ filterLocations();  }}>Go</button>
								</div>
							</div>
							<div className={styles.map}>
								<Map coordinates={useLocation}>
									{ sortedLocations && sortedLocations.map((location, i) => {   
										return (
											<Marker key={"marker-" + i} icon={markerIcon.childImageSharp.fixed.src} position={{ lat: location.birdeyeLocation.location.lat, lng: location.birdeyeLocation.location.lng }} onClick={()=>{showInfo(location.id)}}>
												{ currentMarker === location.id && <InfoWindow onCloseClick={() => setCurrentMarker(null)}>
													<div>
														<Link to={location.link} className={styles.location__name}>View Location</Link>
													</div>
												</InfoWindow> }
											</Marker>
										)
									})} 
								</Map>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		page: wpPage(id: {eq: $id}) {
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			Locations {
				pageHeading
				pageBlurb
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		locations: allWpLocation(sort: {fields: title}) {
			nodes {
			  id
			  link
			  title
				birdeyeLocation {
					businessId
					timezone
					hoursOfOperations {
						day
						isOpen
						workingHours {
							startHour
							endHour
						}
					}
					location {
						lat
						lng
						address1
						address2
						city
						state
						zip
					}
					name
					phone
				}
			  ACFLocation {
				googlePlaceId
				bookMyAppointmentLink {
				  target
				  title
				  url
				}
			  }
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowLightGray: file(name: {eq: "arrow_light_gray"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowDownGray: file(name: {eq: "arrow-down-gray"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		markerIcon: file(relativePath: { eq: "leap_marker.png" }) {
			publicURL
			childImageSharp {
				fixed(width: 17) {
					src
				}
			}
		}
	}
`
